var updateTimer;
var selectedImages = [];
var openedImage = {};

if($('#get-selected-images').length){
    getSelectedImages();
}


$(function(){

    //image modal megnyitása multi szerkesztésre
    $(document).on("click", ".open-multi-image-editor", function(e){
        getSelectedImages();
        let images = $(".listed-image.selected .file-properties");
        $(".preview-image").hide();
        $(".preview-image-wrapper").addClass("multi-editing-image-wrapper");
        $.each(images, function (i, value) {
            $(".preview-image-wrapper").append(
                $("<img>").attr("src", $(value).attr("href")).addClass("multi-preview-image")
            );
        });
        let imageDataUrl = $($(".listed-image.selected .file-properties")[0]).data("data-url");
        getImageData(imageDataUrl);
        $("#image-properties-editor").modal("show");
    });

    //album további képeinek beszúrása
    $(document).on("click", ".open-album-images-container[aria-expanded=false]", function(){
        if(!openedImage.isOpenedAlbumImage){
            openedImage.isOpenedAlbumImage = true;
            const otherAlbumImagesContainer = $(".other-album-images");
            otherAlbumImagesContainer.html("");
            $.each(openedImage.album_file_urls, function (index, url) {
                newImageObject = $("<img>").attr("src", otherAlbumImagesContainer.data("url") + "/" + url);
                otherAlbumImagesContainer.append(newImageObject);
            });
        }
    })

    //Le kell kérdezni nézetváltásnál a kijelölt képek tömbjét
    $(document).on("click", '[name="view"]', function(e){
        getSelectedImages();
        isPagination = true;
    });

    //kukából visszaállítás
    $(document).on('click', '.restoreImage', function(e){

        alert("Ez a funkció még nem működik");
        // e.preventDefault();

        // preloader.fadeIn();

        // var o = $(this);
        // $.ajax({
        //     type: "POST",
        //     url: $(this).data('url'),
        //     dataType : "json",
        //     success: function (data) {
        //         e.preventDefault();
        //         $('.file-properties[data-id="'+ o.data('id') +'"]').parent('div').parent('div').remove();
        //         $("#image-properties-editor").modal('hide');
        //         preloader.fadeOut();
        //     },
        //     error: function (e) {
        //         e.preventDefault();
        //         alert("Hiba történt kérlek, ellenőrizd a konzolt!");
        //         console.log("ERROR : ", e);
        //         preloader.fadeOut();
        //     }
        // });

        // return false;

    });

    //fájl tulajdonságok modal megnyitása
    $(document).on("click", ".file-properties", function(e){
        e.preventDefault();

        //előző következő gomb eltűntetése működés gomb esetén
        if($('.operation-button').attr('data-active') && $(".selected").length > 0){
            $(".prevFileButton, .nextFileButton").hide();
        }

        //törölni kell a multiselect maradványokat ha van
        $(".multi-editing-image-wrapper").removeClass("multi-editing-image-wrapper");
        $(".multi-preview-image").remove();
        $(".preview-image").show();
        //--------------------------

        const thisFileId = $(this).data("id");

        //ha már van kijelölve egy kép is onnantól kezdve kijelöljük a képet, és semmi mást nem csinálunk vele, kivéve ha csoportos szerksztésre hívjuk
        if(selectedImages.length > 0 && !$(this).hasClass("bulk") && !$(this).hasClass("simpleList")){
            const checkbox = $(this).parents(".tile").find(".select-image");
            checkbox.trigger("click.dfp");
            return false;
        }
        // ha bulkra kattintunk akkor állítsuk be a linkjét
        if(selectedImages.length > 0 && $(this).hasClass("bulk")){
            $(this).attr('href', $('.file-properties[data-id="'+ selectedImages[0] +'"]').attr('href'));
        }

        if($(this).hasClass('disabled')){
            alert("nincs Kép kijelölve");
            return false;
        }

        $("#image-properties-editor").modal("show");



        const url = $(this).attr("href");
        navButtonsChangeValue(thisFileId);
        const img = $(".preview-image");
        img.attr("src", url).attr("data_id", thisFileId);


        const imageDataUrl = $(this).data("data-url");
        getImageData(imageDataUrl);
    });

    $(document).on("change", "#create-album-form", function(e){
        e.preventDefault();

        const files = e.target.files;
        const form = $(this);
        const url = form.attr("action");
        const view = "index";

        preloader.fadeIn();

        storeAlbum(url, files, view)

        return false;
    });

    $(document).on("change", "#store-file-form", function(e){

        e.preventDefault();

        const files = e.target.files;
        const form = $(this);
        const url = form.attr("action");
        const view = $(".tiles-container").attr("data-view");

        storeFile(url, files, view);

        return false;
    });

    $(document).on("keyup | change", ".updateImageData input, .updateImageData select, .updateImageData textarea", function(e, submit = true){

        const form = $(this).parents("form");
        const input = $(e.target);

        //ha egy médiát is kiválasztok a médiastoppnál, aktiválni kell a kinti ikont
        if(input.hasClass("media-stop-input")){
            $("#setIsMediaStop").removeClass("active");
            if($(".media-stop-input:checked").length > 0){
                $("#setIsMediaStop").addClass("active");
            }
        }

        if(submit && !input.hasClass("select2-tags") && !input.hasClass("select2-search__field")){

            if(input.attr("name") == "isactive" && input.prop("checked") && $('[name="description"]', form).val() === ""){
                alert("Nem aktiválhatod a képet amíg nincs leírás");
                input.prop('checked', false);
                return false;
            }
            if(typeof $(this).attr('data-visibility') != 'undefined' && $(this).prop('checked')){
                if(!input.hasClass("disableDelay")){
                    input.addClass("disableDelay");
                }

                if($(this).data('visibility') == 'isfornet'){
                    if(!$('input[data-visibility="isactive"]').prop('checked')) {
                        $('input[data-visibility="isactive"]').prop('checked',true).trigger('change');
                    }
                    if(!$('input[data-visibility="ispublic"]').prop('checked')) {
                        $('input[data-visibility="ispublic"]').prop('checked',true).trigger('change');
                    }
                }
                else if($(this).data('visibility') == 'ispublic'){
                    if(!$('input[data-visibility="isactive"]').prop('checked')) {
                        $('input[data-visibility="isactive"]').prop('checked',true).trigger('change');
                    }
                    if(!$('input[data-visibility="isfornet"]').prop('checked')) {
                        $('input[data-visibility="isfornet"]').prop('checked',true).trigger('change');
                    }
                }
                else if($(this).data('visibility') == 'ispublicsale'){
                    if(!$('input[data-visibility="isactive"]').prop('checked')) {
                        $('input[data-visibility="isactive"]').prop('checked',true).trigger('change');
                    }
                    if(!$('input[data-visibility="ispublic"]').prop('checked')) {
                        $('input[data-visibility="ispublic"]').prop('checked',true).trigger('change');
                    }
                    if(!$('input[data-visibility="isfornet"]').prop('checked')) {
                        $('input[data-visibility="isfornet"]').prop('checked',true).trigger('change');
                    }
                }
            }

            clearTimeout(updateTimer);
            if(input.hasClass("disableDelay")){
                updateImageData(form, input);
            } else {
                updateTimer = setTimeout(function(){
                    // console.log("Küldöm az adatot:", input.val());
                    updateImageData(form, input);
                },500);
            }

        }
    });

    //kedvencek közé tesszük a fájlt
    $(document).on("keyup | change", "#setFavourite", function(e){
        const form = $(this);
        const input = $(e.target);
        const url = form.attr("action");
        updateImageData(form, input, url);
    });

    $(document).on("click", ".deleteImage", function(e){
        e.preventDefault();
        if(confirm("Képek törlése, biztos?")){
            if($(this).hasClass('disabled')){
                alert("nincs Kép kijelölve");
                return false;
            }

            if($(this).hasClass("single")){
                let image_id = $(this).data("image-id")
                deleteImages([image_id], function(){
                    $("#image-properties-editor").modal("hide");
                    setTimeout(function(){
                        let tile = $(document).find(".tile[data-id=" + image_id + "]");
                        // console.log(tile);
                        tile.fadeOut(600, function(){
                            tile.remove();
                        });
                    },300)
                });
                return false;
            }
            if(selectedImages.length > 0){
                if($(this).hasClass('selected-images-action')){

                    deleteImages(selectedImages, function(){
                        unSelectAll();
                        $(".selectAlert").fadeOut();

                        $(".listed-image.selected").parents(".tile").fadeOut(600, function(){
                            $(".listed-image.selected").parents(".tile").remove();
                        });
                    });
                }
            }
        }
    });

    $(document).on("click.dfp", ".select-image", function(e){

        const obj = $(this).parents(".listed-image");
        const id = parseInt($(this).attr("data-id"));

        if( $(this).is( ":checked" ) ){
            obj.addClass("selected");
            selectedImages.push(id);
        } else {
            obj.removeClass("selected");

            selectedImages = jQuery.grep(selectedImages, function(value) {
                return value != id;
            });
        }
        // console.log(selectedImages);
        if(selectedImages.length > 0){
            $(".selected-images-action").removeClass('disabled');
        } else {
            $(".selected-images-action").addClass('disabled');
        }

        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: $(this).data('select').split('?')[0],
            cache: false,
            success: function (data) {
                // console.log(data);
                if(selectedImages.length > 0){
                    $(".selectAlert").fadeIn();
                    $(".selectAlert .count").text(selectedImages.length);
                } else {
                    $(".selectAlert").fadeOut();
                }
            },
            error: function (e) {
                console.log("ERROR : ", e);
            }
        });

        return true;
    });

    $(document).on("click", ".select-all", function(e){
        e.preventDefault();
        preloader.fadeIn();


        var data = {};
        if($('.favourites-for-bulk-operation').length){
            data['favourites'] = '';
        }
        if($('.selected-for-bulk-operation').length){
            data['selected'] = '';
        }
        if($('.album_id-for-bulk-operation').length){
            data['album_id'] = $('.album_id-for-bulk-operation').prop('value');
        }

        if($(this).hasClass('global-unselect')){
            $('.select-image').prop('checked', false);
            $('.listed-image').removeClass('selected');
            $(".selected-images-action").addClass('disabled');
            $(".all-selected").addClass('disabled');
        } else {
            $('.select-image').prop('checked', true);
            $('.listed-image').addClass('selected');
            $(".selected-images-action").removeClass('disabled');
            $(".all-selected").removeClass('disabled');
        }

        $.ajax({
            type: "GET",
            enctype: 'multipart/form-data',
            url: $(this).data('url') +'?'+ location.href.split('?')[1],
            data:data,
            cache: false,
            timeout: 600000,
            success: function (data) {
                selectedImages = Object.values(data['data']);

                setSelect2();
                preloader.fadeOut();
                if(selectedImages.length > 0){
                    $(".selectAlert .count").text(selectedImages.length);
                    $(".selectAlert").fadeIn();
                } else {
                    $(".selectAlert").fadeOut();
                }

            },
            error: function (e) {
                alert("Hiba történt, kérlek ellenőrizd a konzolt!");
                console.log("ERROR : ", e);
            },
            complete: function(){
                preloader.fadeOut();
                if(selectedImages.length > 0){
                    $(".selectAlert .count").text(selectedImages.length);
                    $(".selectAlert").fadeIn();
                } else {
                    $(".selectAlert").fadeOut();
                }

                if($('.favourites-for-bulk-operation').length){
                    $('.filterForm').find('input[name="favourites"]').remove();
                }
                if($('.selected-for-bulk-operation').length){
                    $('.filterForm').find('input[name="selected"]').remove();
                }
                if($('.album_id-for-bulk-operation').length){
                    $('.filterForm').find('input[name="album_id"]').remove();
                }

            }
        });


    });

    $(document).on("click", ".prevFileButton, .nextFileButton", function(){
        const url = $(this).attr("data-url");
        const imageDataUrl = $(this).attr("img-data-url");
        const img = $(document).find(".preview-image");
        img.css("opactiy", 0)
            .attr("src", url);

        navButtonsChangeValue($(this).attr("data-id"));
        getImageData(imageDataUrl);
    })

    $(document).on("click", ".deleteAlbum", function(e){
        e.preventDefault();
        const form = $(this).parent("form");
        if(confirm("Biztosan törlöd ezt az albumot?")){
            preloader.fadeIn();
            form.submit();
        }
    })

    $(document).on("click", ".operation-button", function(){
        const el = $(this);
        const url = el.attr("data-url");
        const active = el.attr("data-active");

        $.ajax({
            url: url,
            type: 'POST',
            data: {'mass-update' : active},
        })
        .done(function(data) {
            if(data.status == "success"){
                el.data("active", data.massUpdate);
                if(!data.massUpdate){
                    el.addClass("disabled");
                    el.attr("data-active","false");
                } else {
                    el.removeClass("disabled");
                    el.attr("data-active","true");
                }
            } else {
                alert("Hiba történt: " + data);
            }
        })
        .fail(function(data) {
            alert("Hiba történt: " + data);
        })
        .always(function(data) {
            // console.log(data);
        });

    });

    //csoportos kijelölés műveletei
    $(document).on("keyup | change", ".updateImageData .selected input, .updateImageData .selected select, .updateImageData .selected textarea", function(e, submit = true){

        const input = $(e.target);
        if(submit && !input.hasClass("select2-tags") && !input.hasClass("select2-search__field")){

            //minden "működés" alatt lévő elemet szerkesztek
            if($('.operation-button').data('active')){

                $('.selected [name="' + input.attr("name") + '"]:not([type="checkbox"])').val(input.val());

                $('.selected .commands [name="' + input.attr("name") + '"]').prop("checked", input.prop("checked"));

                $('.selected .custom-checkbox [name="' + input.attr("name") + '"][data-checkbox-id="' + input.data("checkbox-id") + '"]').prop("checked", input.prop("checked"));

            } else {

            }
        }
    });

    $(document).on('select2:select', ".updateImageData .select2-tags", function (e) {

        //A működésnek be kell lenni kapcsolva csak akkor mehet ez !!!!!
        // console.log($('.operation-button'));
        if($('.operation-button').attr('data-active') && $(".selected").length > 0){
            const newOption = new Option(e.params.data.text, e.params.data.text, true, true);
            let elements = $('.selected [name="' + $(e.target).attr("name") + '"]').not($(e.target));
            elements.append(newOption).trigger('change', [false]);
            // console.log("if");
            tagSaveOrRemove(e.target, "PUT", e.params.data.text, selectedImages);
        } else {
            tagSaveOrRemove(e.target, "PUT", e.params.data.text, [$(e.target).attr("data-file-id")]);
        }

    });

    $(document).on('click', '.set_cover_for_album_href', function(e){
        e.preventDefault();
        preloader.fadeIn();
        var o = $("a", this);
        $.ajax({
            type: "GET",
            url: o.attr('href'),
            dataType : "json",
            success: function (data) {
                $(".set_cover_for_album_href a").removeClass("active");
                o.addClass("active");
                preloader.fadeOut();
            },
            error: function (e) {
                alert("Hiba történt, kérlek ellenőrizd a konzolt");
                console.log("ERROR : ", e);
                preloader.fadeOut();
            }
        });

        return false;

    });

    $(document).on('select2:unselecting', ".updateImageData .select2-tags", function (e) {
        if($('.operation-button').attr('data-active') && $(".selected").length > 0){
            let elements = $('.selected [name="' + $(e.target).attr("name") + '"]').not($(e.target));
            $('option[value="' + e.params.args.data.text + '"]' , elements).remove();
            elements.trigger("change", [false]);
            tagSaveOrRemove(e.target, "DELETE", e.params.args.data.text, selectedImages);
        } else {
            tagSaveOrRemove(e.target, "DELETE", e.params.args.data.text, [$(e.target).attr("data-file-id")]);
        }
    });

});

function getImageData(imageDataUrl){
    $.ajax({
        type: "GET",
        url: imageDataUrl,
        async: false,
        dataType: "json",
        success: function (response) {
            filePropertiesChangeData(response);
        }
    });
}

function filePropertiesChangeData(image){


    openedImage = image;
    openedImage.tile = $(".tile.opened[data-id=" + image.id + "]");

    const select2Tags = $("#image-properties-editor .select2-tags");

    //formot módosítani kell különben egy másik kép adatait szerkesztjük
    const form = $(".updateImageData");
    const newUrl = $(document).find(".tile.opened .file-properties").attr("href");
    form.attr("action", newUrl);
    //--------

    //command gombokhoz tartozó url frissítése
    const newCommandUrl = $(document).find("#commandUrl")
        .data("url")
        .replace("##album_id##", image.album[0].id)
        .replace("##img_id##", image.id);


    $("#commandUrl").attr("data-url", newCommandUrl);
    //--------

    //select2 attribútumokat is módosítani kell, mert azok alapján megy a tagek cseréje
    let originalSetTagUrl = $("#setFileTagUrl").data("set-tag-url");
    select2Tags.attr("data-file-id", image.id);
    select2Tags.attr("data-set-tag-url", originalSetTagUrl.replace("##img_id##", image.id));
    //--------

    //egyszerű adatok cseréje
    $(".img-id-and-uploaded").text(image.id + " (" + formatDate(image.uploaded) + ")");
    $("[name=title]").val(image.title);
    $("[name=description]").val(image.description);
    $("[name=photographed]").val(image.photographed);
    $("[name=person_birthday]").val(image.person_birthday);
    $("[name=baseprice_mul]").val(image.baseprice_mul);
    $(".originalfilename").text(image.filename);
    $(".height").text(image.height);
    $(".width").text(image.width);
    $("[name=user_id]").val(image.user_id);
    //-------

    // Kulcsszavak cseréje
    //töröljük az összes select2 mező értékét
    select2Tags.val(null)
                .find('option')
                .remove()
                .end()
                .trigger('change');
    //--------

    // Hozzáadjuk az új értékeket
    $.each(image.tags, function (index, tag) {
        const newOption = new Option(tag.tag, tag.tag_id, true, true);
        const select2input = $(document).find('#image-properties-editor [data-tag_cat_id="' + tag.tag_cat_id + '"]')
        select2input.append(newOption).trigger("change");
    });
    //--------


    //Meta infók cseréje
    $.each(image.metas, function(index, meta){
        if(meta.meta_name != "copyright"){
            $("." + meta.meta_name).text(meta.meta_value);
        }
    });
    //---------

    //Média stop tartalom cseréje
    $(".media-stop-input").prop("checked", false);
    $.each(image.mediastop, function(index, medium){
        $('.media-stop-input[value="' + medium.medium_id + '"]').prop("checked", true);
    });
    //---------

    //command gombok kitöltése
    $("#setIsactive").prop("checked",image.isactive == 1 ? true : false);
    $("#setIspublic").prop("checked",image.ispublic == 1 ? true : false);
    $("#setIspublicsale").prop("checked",image.ispublicsale == 1 ? true : false);
    $("#setIsfornet").prop("checked",image.isfornet == 1 ? true : false);
    $("#setIsstock").prop("checked",image.isstock == 1 ? true : false);
    $("#setIsmodell").prop("checked",image.ismodell == 1 ? true : false);
    $("#setIsmetaok").prop("checked",image.ismetaok == 1 ? true : false);
    $("#setIsnew").prop("checked",image.isnew == 1 ? true : false);
    $("#setIsMediaStop").removeClass("active").addClass(image.mediastop.length > 0 ? "active" : "");
    $("#setIsfavourite_ListAndModal").prop("checked",image.isfavourite == 1 ? true : false);
    let isCoverButton = $(".set_cover_for_album_href a");
    if(isCoverButton.length > 0){
        let url = isCoverButton.data("url").replace("##img_id##", image.id);
        url = url.replace("##album_id##", image.album[0].id);
        isCoverButton.attr("href", url);
        if(image.iscover == 1){
            isCoverButton.addClass("active");
        } else {
            isCoverButton.removeClass("active");
        }
    }
    //---------

    //nézőkép letöltése gomb adatok kitöltése
    let text = "";

    let pidAdmin = $(".preview-image-download.admin");
    if(pidAdmin.length > 0){
        text = $(".preview-image-download-href.admin").text().replace('##file_id##', image.id);
        pidAdmin.attr("href", text);
    }

    let pidGuest = $(".preview-image-download.guest");
    if(pidGuest.length > 0){
        text = pidGuest.attr("data-vc-file_id").replace('##file_id##', image.id);
        pidGuest.attr("data-vc-file_id", text);

        text = $(".preview-image-download-download.guest").text();
        text = text.replace('##file_id##', image.id);
        text = text.replace('##user_signature##', image.signature);
        if(image.photographed) text = text.replace('##photographed##', image.photographed.split(" ")[0]);
        pidGuest.attr("download", text);

        text = $(".preview-image-download-href.guest").text().replace('##image_url##', image.public_url);
        pidGuest.attr("href", text);
    }
    //---------

    //statisztikai adatok kitöltése
    $(".preview-downloads").text(image.preview_download_datas.length);
    const previewDownloadsData = image.preview_download_datas
        .map(data => `<div class="d-flex flex-column">${data.media_name} (${data.user_full_name}) ${data.created_at}<div>`)
        .join("");
    $(".preview-downloads").attr("data-original-title", previewDownloadsData);
    $(".preview-downloads").tooltip({
        html: true
    });

    $(".file-views").text(image.view_datas.length);
    console.log(image.view_datas);
    const fileViewData = image.view_datas
        .map(data => `<div class="d-flex flex-column">${data.media_name} (${data.user_full_name}) ${data.created_at}<div>`)
        .join("");
    $(".file-views").attr("data-original-title", fileViewData);
    $(".file-views").tooltip({
        html: true
    });


    $(".purchased").text(image.purchased_datas.length);
    const prchasedData = image.purchased_datas
        .map(data => `<div class="d-flex flex-column">${data.media_name} (${data.user_full_name}) ${data.order_date}<div>`)
        .join("");
    $(".purchased").attr("data-original-title", prchasedData);
    $(".purchased").tooltip({
        html: true
    });
    //---------


    //vásárlási gombok adatainak kitöltése
    let fastOrderForm = $(".fast-order-form");
    let fastOrderFormButton = $(".fast-order-form button");
    if(fastOrderForm.length > 0){
        let url = fastOrderForm.data("url").replace("##img_id##", image.id);
        fastOrderForm.attr("action", url);
        fastOrderFormButton.data("confirm-message", image.confirm_message);
    }
    //---------


    //törlés és visszaállítás gomb kitöltése
    let deleteImageButton = $(document).find(".deleteImage.single");
    if(deleteImageButton.length > 0){
        deleteImageButton.data("image-id", image.id);
    }

    let restoreImageButton = $(".restoreImage.single");
    if(restoreImageButton.length > 0){
        restoreImageButton.data("image-id", image.id)
    }
    //---------

}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hour = d.getHours(),
        min = d.getMinutes(),
        sec = d.getSeconds();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-') + " " + [hour, min, sec].join(':');
}

function navButtonsChangeValue(openedFileId = false){

    $(".tile").removeClass("opened");
    let openedFile = $(document).find('.tile[data-id="' + openedFileId + '"]');
    openedFile.addClass("opened");


    if(!openedFileId) openedFileId = openedFile.attr("data-id");

    const nextFileId = openedFile.next(".tile").data("id");
    const prevFileId = openedFile.prev(".tile").data("id");


    const next = $(".nextFileButton");
    if(nextFileId !== undefined){
        const next_url =  next.data("url").replace("##img_id##", nextFileId);
        const next_data_url =  next.data("img-data-url").replace("##img_id##", nextFileId);
        next.val(nextFileId)
            .removeAttr("disabled")
            .attr("data-url", next_url)
            .attr("data-id", nextFileId)
            .attr("img-data-url", next_data_url);
    } else{
        next.attr("disabled", "disabled");
    }

    const prev = $(".prevFileButton");
    if(prevFileId !== undefined){
        const prev_url = prev.data("url").replace("##img_id##", prevFileId);
        const prev_data_url = prev.data("img-data-url").replace("##img_id##", prevFileId);
        prev.val(prevFileId)
            .removeAttr("disabled")
            .attr("data-url", prev_url)
            .attr("data-id", prevFileId)
            .attr("img-data-url", prev_data_url);
    } else{
        prev.attr("disabled", "disabled");
    }
}

function getSelectedImages(){
    const  url = $('#get-selected-images').data('url');
    if(!$('#get-selected-images').length){
        return false;
    }
    $.ajax({
        url: url,
        type: 'GET',
        async: false,
        cache:false,
        data: {}
    }).done(function(data) {
        selectedImages = Object.values(data);
        if(selectedImages.length > 0){
            $(".selectAlert .count").text(selectedImages.length);
            $(".selectAlert").fadeIn();
            $(".selected-images-action").removeClass("disabled");
        }
    })
    .fail(function(data) {
        alert("Hiba történt: " + data);
    })
    .always(function(data) {
        // console.log(data);
    });
}

function updateImageData(form, input, url = false){

    $(".mini-loader").fadeIn();

    if(url == false){
        url = $("#commandUrl").attr("data-url");
    }


    var data = new FormData();

    if($.isArray(input.val())) {

        $(input.val()).each(function(e,value) {
            data.append(input.attr("name"), value);
       });

    } else if( input.attr("type") == "checkbox" && !input.is(":checked") && input.attr("name") !== "media[]" ){
        data.append(input.attr("name"), "0");
    }
    else{
        data.append(input.attr("name"), input.val());
    }




    if($('#image-bulk-modify').length){
        data.append('editimages', selectedImages);
    }



    const duplicated = input.parents("[data-duplicated]").attr("data-duplicated");
    const toDuplicated = $(document).find('[data-duplicated="' + duplicated + '"] input');
    toDuplicated.prop("checked", input.prop("checked"));

    if(input.prop("checked")){
        input.parents(".custom_checkbox").find("i").addClass("active");
        toDuplicated.parents("[data-duplicated]").find("i").addClass("active");
    } else {
        input.parents(".custom_checkbox").find("i").removeClass("active");
        toDuplicated.parents("[data-duplicated]").find("i").removeClass("active");
    }

    data.append("_method", "PUT");


    input.css("background", "lightyellow");

    $.ajax({
        type: "POST",
        enctype: 'multipart/form-data',
        url: url,
        data: data,
        processData: false,
        contentType: false,
        cache: false,
        timeout: 600000,
        success: function (data) {
            input.css("background", "#f2f2f2");
            input.parents(".form-group").css("border", "none");
        },
        error: function (e) {
            console.log("ERROR : ", e);
            input.parents(".form-group").css("border", "solid #fbb0b0");
        },
        complete : function(){
            $(".mini-loader").fadeOut();
            preloader.fadeOut();
        }
    });
}


var loader = 1;

function storeAlbum(url, files, view) {

    loader = 1;

    $.ajax({
        type: "POST",
        url: url,
        success: function (data) {

            //  Frissítjük az url-t, hogy a scroll function jó helyről kérdezzen
            history.pushState({}, null, data.url + "&view=" + view);

            $("#gallery").html(data.content);

            setSelect2();

            const storeFileUrl = $("#store-file-form").attr("action");

            storeFile(storeFileUrl, files, view);
        },
        error: function (e) {
            console.log("ERROR : ", e);
        }
    });
}



function storeFile(url, files, view) {
    isPagination = false;
    preloader.fadeIn();
    let formData = new FormData();
    formData.set("view", view);

    // Indítsd az első fájl feltöltését
    uploadNextFile(url, files, formData, 0);
}

function uploadNextFile(url, files, formData, currentIndex) {
    if (currentIndex < files.length) {
        const file = files[currentIndex];
        formData.set("file", file);
        const src = URL.createObjectURL(file);
        $(".uploading-image").fadeIn();
        formData.set('n', currentIndex + 1);

        ajaxImageUpload(url, formData, files, currentIndex + 1, function() {
            // A következő fájl feltöltése csak a jelenlegi befejezése után indul
            uploadNextFile(url, files, formData, currentIndex + 1);
        });
    } else {
        // Minden fájl feltöltése befejeződött
        preloader.fadeOut();
        isPagination = true;
    }
}

function ajaxImageUpload(url, formData, files, currentIndex, callback) {
    $.ajax({
        type: "POST",
        enctype: 'multipart/form-data',
        url: url,
        data: formData,
        processData: false,
        contentType: false,
        cache: false,
        timeout: 600000,
        async: true,
        success: function (data) {
            $(".tiles-container").prepend(data);

            loaderIndicator(currentIndex, files.length, function(){
                // console.log("callback visszatér");
            });

            if (callback) {
                callback();
            }
        },
        error: function (e) {
            preloader.fadeOut();
            console.log("ERROR : ", e);
            let errorMessages = "Hiba történt a feltöltés közben kérlek ellenőrizd a konzolt.<br>";
            errorMessages += e.responseJSON.message;
            alert(errorMessages);
            return false;
        }
    });
}


function deleteImages(files, callback){
    const url = $('meta[name=files-delete]').attr("content");
    $.ajax({
        type: "DELETE",
        enctype: 'multipart/form-data',
        url: url,
        async: false,
        data:{
            'deleteimages':files
        },
        //processData: false,
        //contentType: false,
        cache: false,
        timeout: 600000,
        success: function (data) {
            callback();
        },
        error: function (e) {
            alert("A képek törlése közben hiba történt!");
            console.log("ERROR : ", e);
        }
    });
}


function unSelectAll(){
    const url = $('meta[unselect-all-file]').attr("content");
    $.ajax({
        type: "GET",
        enctype: 'multipart/form-data',
        url: url,
        async: false,
        //processData: false,
        //contentType: false,
        cache: false,
        timeout: 600000,
        success: function (data) {
            selectedImages = [];
        },
        error: function (e) {
            alert("Az összes kijelölés törlése közben hiba történt.");
            console.log("ERROR : ", e);
        }
    });

}

function tagSaveOrRemove(o, method, tagText, filesId){
    $.ajax({
        url: $(o).attr('data-set-tag-url'),
        type: method,
        cache:false,
        data: {
            tag : tagText,
            ids : filesId,
            method : method,
            tag_cat_id : $(o).data('tag_cat_id')
        },
    })
    .done(function(response) {
        if(response[0] == 'OK'){
            // console.log(response);
        } else {
            alert("hiba1: ellenőrizd a konzolt");
            console.log(response);
        }
    })
    .fail(function(error) {
        alert("hiba2: ellenőrizd a konzolt");
        console.log(error);
    })
    .always(function() {
        // console.log("complete");
    });

}

$(function(){

    //Le kell kérdezni nézetváltásnál a kijelölt képek tömbjét
    $(document).on("click", '.viewcounter', function(e){
        if(typeof $(this).data('file_id') == 'undefined'){
            return false;
        }
        if(selectedImages.length > 0 && !$(this).hasClass("bulk") && !$(this).hasClass("simpleList")){
            return false;
        }
        $.ajax({
            url: rootUrl + "/view-counter/" + $(this).data('file_id'),
            type: 'GET',
            cache:false,
            data: { },
        })
        .done(function(response) {

        })
        .fail(function(error) {
            alert("hiba: " + error);
        })
        .always(function() {
            // console.log("complete");
        });

    });

    $(document).on("click", '.view_dl_counter', function(e){

        if(typeof $(this).attr('data-vc-file_id') == 'undefined'){
            return false;
        }

        $.ajax({
            url: rootUrl + "/view-dl-counter/" + $(this).attr('data-vc-file_id'),
            type: 'GET',
            cache:false,
            data: { },
        })
        .done(function(response) {

        })
        .fail(function(error) {
            alert("hiba: " + error);
        })
        .always(function() {
            // console.log("complete");
        });

    });

    $(".preview-image-wrapper img").click(function(e){
        $(".preview-image-wrapper").toggleClass("opened");
        $(".preview-image-data-wrapper").toggleClass("opened");
    });

});
